/* Bakgrund för Home-sidan */
.home-background {
    
    background-size: 100%;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
   
  }

  /* Titel-stil */
.home-title {
    font: 60px "Old Standard TT", serif;
    color: #666666;
    
    margin: 0;
    padding: 20px;
    text-align: center;
    margin-top: 150px;
}

.home-description {
    font: 16px Mulish, sans-serif;
    color: #666666;
    text-align: center;
    margin: 0 auto; /* Centrerar elementet horisontellt */
    padding: 10px 20px; /* Lägger till lite padding på sidorna för bättre läsbarhet */
    max-width: 60%; /* Minskar bredden för att komprimera texten mer */
    
    line-height: 1.4; /* Minskad line-height för mer kompakt text */
}

/* För mindre skärmar (mobiler) */
@media (max-width: 768px) {
    .home-background {
        background-size: contain; /* Visa hela bilden på mindre skärmar */
        background-position: center;
        background-color: #f5f5f5; /* Lägg till en bakgrundsfärg om det finns utrymme */
    }

    .home-title {
        font-size: 2rem; /* Mindre textstorlek för mobiler */
    }
}
